.custom-navigation-bar {
  .navbar-brand {
    img {
      height: 5vh;
      width: auto;
    }
  }

  .nav-link {
    &.active {
      text-decoration: underline;
      text-underline-offset: 0.5rem;
      text-decoration-thickness: 0.2rem;
    }
  }

  .flag-toggler-container {
    width: fit-content;
  }

  transition: 0.2s;
}
