.legalitas-links {
  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-underline-offset: 0.5rem;
    }
  }
}
