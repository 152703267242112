.custom-bg-stripes {
  background-image: linear-gradient(
    135deg,
    #ffffff 8.33%,
    #fea601 8.33%,
    #fea601 50%,
    #ffffff 50%,
    #ffffff 58.33%,
    #fea601 58.33%,
    #fea601 100%
  );
  background-size: 8.49px 8.49px;
}

.jadwal-kegiatan {
  p {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
