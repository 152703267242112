// Override default variables before the import
$theme-colors: (
  primary: #00a8f4,
  warning: #fea601,
  secondary: #636363,
);

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 2rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
