.head-article {
  .card,
  .card-img,
  .card-img-overlay {
    border-radius: 0;
  }

  .card-img-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    top: initial;
  }

  .card-secondary {
    height: 11rem;

    .card-title,
    .card-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &.mobile {
    .card-img-overlay {
      top: 0;
    }

    .card-title,
    .card-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.student-counter {
  background-color: #e0e0e0;
}
