.custom-horizontal-line {
  width: 10%;
  max-width: 3rem;
  border-width: 0.2rem;
  border-color: #000000;
  opacity: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.flag-toggler {
  width: 2rem;
  height: 1rem;
  margin: 0 0.5rem;
}
